@use 'colors';

@tailwind base;
@tailwind components;
@tailwind utilities;

$sidenav-size: 53px;

/* You can add global styles to this file, and also import other style files */
body, html {
    margin: 0;
    height: 100%;
}

body {
    margin: 0;
}

.no-margin {
    margin: 0 !important;
}

.no-padding {
    padding: 0 !important;
}

.line-breaker {
    white-space: break-spaces;
}

.title {
    margin-bottom: 16px !important;
}

.h1-title {
    margin-top: 5px;
    font-size: 24px;
    @extend .title;

    h1 {
        font-size: 22px;
    }
}

.position {
    display: inline-block;
    width: 100%;
}

.h2-title {
    font-size: 24px !important;
    @extend .title;
}

.h3-title {
    font-size: 22px !important;
    @extend .title;
}

.card-tables {
    padding: 1px !important;
    overflow: hidden;
}

.delete-item {
    background-color: transparent;

    &:hover {
        background-color: rgba(0, 0, 0, 0.16);
    }
}

.check-slider {
    min-width: 60px !important;
}

.my-chip {
    border-radius: 15px;
    padding: 4px 8px;
    font-size: 13px;

    mat-icon {
        font-size: 21px !important;
    }
}

.warn-icon {
    color: colors.$warn-color;
    font-size: 20px;
    cursor: help;
}

.info-icon {
    color: colors.$info-color;
    font-size: 20px;
    cursor: help;
    height: 20px;
    width: 20px;
}

.edit-icon{
    font-size: 20px;
    height: 20px;
    width: 20px;
}

.error-icon {
    color: colors.$error-color;
    font-size: 20px;
}

.bg-gradient {
    background: linear-gradient(0deg, #ffffff 0%, colors.$cinza-escuro 90%);
}

.container-sides {
    padding: 0 25px;
}

.mat-drawer-content {
    background-color: #f9f9f9;
}

.remove-loading {
    display: none !important;
}

.dialog-campanha {
    position: relative !important;
}

.icon-help {
    font-size: 16px;
    height: 16px;
    width: 16px;
}

.mat-mdc-tooltip {
    font-size: 12px !important;
}

.tooltip-warn {
    @extend .warn-bg;
}

.tooltip-info {
    @extend .info-bg;
}

.mat-drawer-content {
    overflow-y: auto !important;
    overflow-x: hidden !important;
}

.full-h {
    min-height: calc(100vh - 52px);
}

.buttons-fixed {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;
    background: white;
    .buttons-wrapper {
        padding: 0 25px !important;
    }
}

@media(max-width: 599px) {
    .buttons-fixed .buttons-wrapper {
        padding: 0 10px 0 calc($sidenav-size + 10px) !important;
    }
}

.card-top {
    padding-top: 0 !important;
}

.link-table-ec {
    color: black !important;
    cursor: pointer
}

.angular-editor-toolbar {
    font-size: 15px !important;
}

.mat-mdc-dialog-title {
    @extend .primary-text;
}

.select-idiomas {
    padding-top: 10px;
    color: #310035 !important;

    mat-label {
        color: #310035 !important;
    }

    .mat-mdc-select-value-text {
        color: #310035 !important;
    }

    .mat-mdc-select-arrow {
        color: #310035 !important;
    }
}

.disabled {
    opacity: 0.4 !important;
}

.button-font {
    font-size: 14px;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    font-weight: 500;
    border-radius: 5px;
}

.capiton {
    font-size: 12px;
    letter-spacing: 0.4px;
    font-weight: 400;
}

mat-checkbox label {
    white-space: normal !important;
    line-height: 18px;
}

.margin-left-sidenav-size {
    margin-left: $sidenav-size;
}

.box-outline {
    margin-top: 10px;
    overflow-x: scroll;
    padding: 15px 10px 10px;
    border: 1px solid lightgrey;
    border-radius: 8px;
    margin-bottom: 15px;

    .label {
        position: absolute;
        background-color: white;
        margin-top: -25px;
        margin-left: 10px;
        padding: 0 5px;
    }

    mat-checkbox {
        margin-bottom: 8px;
    }
}

.noselect {
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
}

.overline {
    font-size: 10px;
    letter-spacing: 1.5px;
    font-weight: 400;
}

/* SCROLL */
::-webkit-scrollbar-track {
    background-color: rgba(255, 255, 255, 0.659);
}

::-webkit-scrollbar {
    width: 6px;
    height: 8px;
    background: #F4F4F4;
}

::-webkit-scrollbar-thumb {
    background: #dad7d7;
    border-radius: 5px;
}

.mat-drawer-backdrop.mat-drawer-shown {
    visibility: hidden !important;
}

/*ANIMAÇÕES */
@keyframes slideInLeft {
    from {
        -webkit-transform: translate3d(-100%, 0, 0);
        transform: translate3d(-100%, 0, 0);
        visibility: visible;
    }

    to {
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes fadeOut {
    from {
        opacity: 1;
    }

    to {
        opacity: 0;
    }
}

@keyframes fadeInRight {
    from {
        top: 0;
        opacity: 0;
        -webkit-transform: translate3d(10%, 0, 0);
        transform: translate3d(10%, 0, 0);
    }

    to {
        top: 0;
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

@keyframes fadeInLeft {
    from {
        opacity: 0;
        -webkit-transform: translate3d(-10%, 0, 0);
        transform: translate3d(-10%, 0, 0);
    }

    to {
        opacity: 1;
        -webkit-transform: translate3d(0, 0, 0);
        transform: translate3d(0, 0, 0);
    }
}

.menuCustom {
    border-radius: 15px;
}

.row {
    margin: 0 -8px;
}

.row > div {
    padding: 0 8px;
}

.panel-cadastros .mat-mdc-table {
    width: 100%;
    overflow: auto;
}

.clickable {
    cursor: pointer;
}

.help {
    cursor: help;
}

.disable {
    opacity: 0.6;
}

.disabled-calendar-day {
    background: #e0e0e0 !important;
    border-radius: 90px;
    color: black !important;
    opacity: 0.6;
}

.bg-red-x {
    background: #ff6257;
    color: colors.$error-color !important;
    border-radius: 90px;
}

.panel-cadastros h1 {
    font-size: 30px;
    width: 100%;
}

.panel-cadastros .mat-mdc-paginator {
    margin-bottom: 36px;
}

.button-novo {
    position: fixed !important;
    right: 16px;
    bottom: 16px;
    width: 128px;
    border-radius: 30px !important;
    z-index: 9999 !important;
}

.panel-cadastros .edit-row-table {
    background-color: transparent;
    border-radius: 50%;
    border: none;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.panel-cadastros .edit-row-table:hover {
    cursor: pointer;
    background-color: rgba(100, 100, 100, 0.24);
}

.panel-cadastros .tr-table:hover {
    background-color: rgba(100, 100, 100, 0.16)
}

.sinc-logs .position-loading {
    margin-top: -4px;
}

.mat-mdc-checkbox label {
    margin: 0;
}

.text-sort-end .mat-sort-header-container {
    justify-content: flex-end;
}

// TRATAMENTO DE ERRO
.mat-carregamento {
    width: 85%;
    margin-top: 64px;
    margin-left: auto;
    margin-right: auto;
}

/*
* Head Elementos
*/

.panel-cadastros .edit-row-table {
    background-color: transparent;
    border-radius: 50%;
    border: none;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.panel-cadastros .edit-row-table:hover {
    cursor: pointer;
    background-color: rgba(100, 100, 100, 0.24);
}

.panel-cadastros .tr-table:hover {
    background-color: rgba(100, 100, 100, 0.16)
}

.sinc-logs .position-loading {
    margin-top: -4px;
}

.mat-mdc-checkbox label {
    margin: 0;
}

.text-sort-end .mat-sort-header-container {
    justify-content: flex-end;
}

.logo-text {
    color: #575555 !important;
}

.logo-icon {
    color: #575555 !important;
}

.header-description {
    flex-direction: row;
    box-sizing: border-box;
    display: flex;
    place-content: center flex-start;
    align-items: center;
}

.cdk-global-scrollblock {
    position: inherit;
}

/*
* form Elementos
*/

.toggle-slide {
    margin-top: 15px !important;
}

/*
* Footer form
*/

.divider {
    width: 1px;
    height: 40px;
    background-color: #e0e0e0;
    position: absolute;
    bottom: 23px;
    left: 355px;
}

.btns-action {
    margin-bottom: -15px;
    margin-left: -15px;
    margin-right: -15px;

    button {
        width: 150px;
        text-align: center;
        border-width: inherit;
        margin: 15px 15px 15px 15px;
    }
}

.snackBarError {
    .mdc-snackbar__surface {
        background: colors.$error-color !important;
    }

    color: whitesmoke !important;

    .mdc-button__label {
        color: white !important;
    }
}

.snackBarInfo {
    .mdc-snackbar__surface {
        background: colors.$info-color !important;
    }

    color: whitesmoke !important;

    .mdc-button__label {
        color: white !important;
    }
}

.snackBarSuccess {
    .mdc-snackbar__surface {
        background: colors.$success-color !important;
    }

    color: whitesmoke !important;

    .mdc-button__label {
        color: white !important;
    }
}

.wd400-95pc {
    width: 400px;
    max-width: 100%
}

.password-input {
    transition: 0.1s;
    filter: blur(7px);

    &:focus {
        filter: none;
    }
}

.filtro {

    span {
        position: relative !important;
        left: 34px;
        top: -11px;
    }

    input {
        margin-bottom: 10px;
        width: 100%;
        max-width: 500px;
        float: right;
        border-radius: 21px;
        border: 1px solid rgba(0, 0, 0, .16);
        padding: 4px 12px 4px 32px;
        height: 40px;
    }
}

.filtro:focus {
    outline: none;
}


.filtro-avancado {
    height: 270px !important;
}

@keyframes rotating {
    from {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }

    to {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
}

.rotating {
    -webkit-animation: rotating 1s linear infinite;
    animation: 1s linear infinite rotating ;
}

.popup-alert .mat-mdc-dialog-surface {
    background-color: #f56c63 !important;
    color: whitesmoke !important;
    /* padding: 0; */
}

.big-extrato-cards {
    padding: 2px !important;
}

consulta-extrato {
    justify-content: center;
    align-items: stretch;
    display: flex;
}

.expanded-table {
    min-width: 1000px !important;
}

.expanded-table .mat-mdc-cell {
    padding-right: 10px !important;
}

.expanded-table .td {
    max-height: 50px !important;
}

.white-spinner {
    circle {
        stroke: whitesmoke !important;
    }
}

.svg-fill {
    svg {
        max-width: 100% !important;
        // max-height: 100% !important;
        width: 100% !important;
        max-height: 90vh !important;
        height: 100% !important;
    }
}

@keyframes highlight-seat {
    from {
        filter: brightness(50%);
    }
    to {
        filter: brightness(100%);
    }
}

.gap-mat-select {
    .mat-mdc-select-trigger {
        gap: 4px;
    }
}

.chip-listbox-horizontal {
    >div {
        flex-direction: row;
        flex-wrap: nowrap;
    }
}
